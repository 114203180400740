<template>
  <!--
    The view for the Installation Converter Configs
  -->
  <div class="installationDetail_ConverterConfigs">
    <template>
      <div class="card">
        <Portlet
          :title="$t('kibana.errorsToday')"
          class="mb-3"
          icon="exclamation-triangle"
        >
          <template slot="buttons">
            <font-awesome-icon
              :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loadingErrors}]"
              icon="sync-alt"
              @click="getErrors()"
            />
          </template>
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <KibanaErrorsList
              ref="errorsList"
              :resort-id="installationObject.resortId"
              @stopLoading="sLoading"
            />
          </template>
        </Portlet>
        <Portlet
          :title="$t('menu.converterConfigs')"
          icon="clipboard-check"
        >
          <template slot="buttons">
            <button
              class="btn btn-sm btn-primary float-right"
              @click="showSidebarAdd"
            >
              <font-awesome-icon
                class="mr-1"
                icon="plus"
              />
              <span>{{ $t('converterConfigs.add') }}</span>
            </button>
          </template>
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <a
              :href="'https://admin.skiline.cc/php/alturos/content/admin/id/tool/resortTicketExamples.php?resort='+installationObject.resortId"
              target="_blank"
            >Live Data</a>
            <converterConfigsList
              ref="converterConfigsList"
              :resort-id="installationObject.resortId"
              @reloadAuditLogs="reloadAuditLogs"
            />
            <DatabaseAuditLogList
              ref="databaseAuditLogList"
              :resort-id="installationObject.resortId"
              @reload="reloadConverterConfigs"
            />
          </template>
        </Portlet>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "InstallationDetailConverterConfigs",
  components: {
    ConverterConfigsList: () =>
      import("@/components/ConverterConfigs/ConverterConfigsList.vue"),
    DatabaseAuditLogList: () =>
      import("@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue"),
    KibanaErrorsList: () =>
      import("@/components/Installation/KibanaErrorsList.vue")
  },
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      loadingErrors: true,
      installationObject: null
    };
  },
  metaInfo () {
    return {
      title: this.$t("menu.converterConfigs")
    };
  },
  created () {
    this.getInstallation();
  },
  methods: {
    sLoading () {
      this.loadingErrors = false;
    },
    getErrors () {
      this.loadingErrors = true;
      this.$refs.errorsList.getKibanaErrors();
    },
    reloadConverterConfigs () {
      this.$refs.converterConfigsList.reloadConverterConfigs();
    },
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    showSidebarAdd () {
      this.$refs.converterConfigsList.showSidebarAdd();
    },
    getInstallation () {
      this.loading = true;
      this.loadingErrors = true;
      this.axios
        .get("/Installation/Get?id=" + this.installationId)
        .then(response => {
          this.installationObject = response.data;
        })
        .finally(() => {
          this.loading = false;
          this.loadingErrors = false;
        });
    }
  }
};
</script>